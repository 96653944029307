import React from "react";
import { Link } from "react-router-dom";
import '../not-found/NotFound.css'

const NotFound = () => {
  return (
    <div className="notFound">
        <div className="details">
      <h1>404 - Page Not Found</h1>
      <p>The page you are looking for does not exist.</p>
      <Link to="/">Go to Homepage</Link>
      </div>
    </div>
  );
};

export default NotFound;
