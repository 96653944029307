import React from "react";
import { useState, useEffect, useCallback} from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import "../Jungle-home/JungleHome.css";
import vectorImg from "../../images/Vector.png";
import propertyImg from "../../images/property-1.png";
import birdImg from "../../images/birdsImg.png";
import topleafImg from "../../images/top-leaf.svg";
import homeImage from "../../images/homeSVG.webp";
import secondBtmImg from "../../images/sec-bottom.svg";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import topGroupImg from "../../images/about-group.svg";
import map from "../../images/map.webp";
import mapBottom from "../../images/map-btm.svg";
import Navbar from "../navbar/Navbar";
import FooterPage from "../footer-page/FooterPage";
import 'bootstrap/dist/js/bootstrap.bundle.min';
import "bootstrap/dist/css/bootstrap.min.css";
import ReactPageScroller from 'react-page-scroller';
import homeTree from "../../images/mountainWEBP.webp";
import Forest from "../forest/Forest";

const JungleHome = () => {
  const location = useLocation();
  const canonicalUrl = `${window.location.origin}${location.pathname}`;
  const [pageNumber, setPageNumber] = useState(0);
  const [ismageLoading, setImageLoading] = useState(true);

  const handleImageLoad = () => {
    setImageLoading(false);
  };
  

    const paraGroup = [
    {
      para1: "Natural Beauty & Green Fresh Air",
      para2: "Live surrounded by serene landscapes and breathe in unpolluted air."
    },
    {
      para1: "Forest Beauty & Green Fresh Air",
      para2: "Live surrounded by serene landscapes and breathe in unpolluted air."
    },
    {
      para1: "Small Beauty & Green Fresh Air",
      para2: "Live surrounded by serene landscapes and breathe in unpolluted air."
    },
    {
      para1: "Where Beauty & Green Fresh Air",
      para2: "Live surrounded by serene landscapes and breathe in unpolluted air."
    },
    
  ];
  
  const handlePageChange = useCallback((number) => {
  if (number >= 0 && number < paraGroup.length) {
    setPageNumber(number);
  }
}, [paraGroup.length]);

  
  
  useEffect(() => {
    const handleWheel = (event) => {
      // Prevent default scrolling behavior
      event.preventDefault();
    };

    // Attach wheel event listener to prevent scrolling
    const pageScrollerElement = document.querySelector('.para_grp_text');
    if (pageScrollerElement) {
      pageScrollerElement.addEventListener('wheel', handleWheel, { passive: false });
    }

    // Cleanup on unmount
    return () => {
      if (pageScrollerElement) {
        pageScrollerElement.removeEventListener('wheel', handleWheel);
      }
    };
  }, []);

  return (
    <>
      <Helmet>
            <title>Home</title>
            <meta name="description" content="Discover top-tier services at Jungle View Homes, including expert construction, advanced security, and seamless architectural planning, ensuring a safe and comfortable living experience." 
 />
            <link rel="canonical" href={canonicalUrl} />
          </Helmet>
    <div className="jungle" id="home">
      {/* HOME PAGE */}
      {ismageLoading && (
      <div className="tree-skeleton">
        <Skeleton height={0} />
      </div>
    )}
    {!ismageLoading && (
      <div className="home_container">
        <div className="home_overlay"></div>

        <header className="home_top_nav">
        <Navbar />
      </header>

        <div className="home_tree">
        <img
          src={homeTree}
          alt="Mountain view at Jungle View Homes"
          className="img-fluid"

        />
        </div>

        <div className="center_text container">
        <h1 className="discover_text">
          <span className="discover">DISCOVER</span>
          <span className="highlight">YOUR OASIS IN NATURE</span>
        </h1>
        <p className="discover_sub_text">
          <div className="discover_highlight">Jungle View Homes: </div>
          Where Serenity Meets Luxury
        </p>
        <a
          href="/property"
          className="explore_text"
          title="Explore available plots and properties at Jungle View Homes"
          aria-label="Explore available plots at Jungle View Homes"
        >
          Explore Our Plots
        </a>

        </div>

        <div className="btm_img_home">
        <img
          src={vectorImg}
          alt="Decorative vector"
          className="img-fluid"
loading="eager" fetchpriority="high"        />
        </div>
      </div>
    )}

    <img
      src={homeTree}
      alt="Preloading Home Tree"
      style={{ display: "none" }}
      onLoad={handleImageLoad}
    />
      
      {/* PROPERTY INFO PAGE  */}
      <div className="property_container">
       
        <div className="property_img">
          <img src={propertyImg} alt="Luxury Property at Jungle View Homes" className="img-fluid" loading="lazy"></img>
          <div className="bird_img">
            <img src={birdImg}  alt="Small Bird Sitting on a Tree Branch" className="img-fluid" loading="lazy"></img>
          </div>
          <div className="property_text container">
          <h2 className="who_text">
        <span className="highlight">WHO</span>
        <span className="highlight_sub_text"> WE ARE</span>
      </h2>
            <div className="para_text">
              <p>
                At Jungle View Homes, we believe in creating a harmonious living
                experience that blends luxury with nature.{" "}
              </p>
              <p>
                Nestled in the heart of a breathtaking forest, our plots offer a
                unique opportunity to build your dream home surrounded by the
                beauty of the wild.Where Serenity Meets Luxury
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* LIFE AT PAGE  */}
      {!ismageLoading && (<div className="lifeAt_container" id="para_grp_text">
        <div className="top_leaf_image">
          <img src={topleafImg}       alt="Decorative Top Leaf - Jungle View Homes" loading="lazy"
          ></img>
        </div>
        <h2 className="life_txt container">
          <span className="highlight">Why Choose</span> Jungle View Homes?
        </h2>
      
        <div className="life_center_box container"
        >
            
          <div className="box_align">
          <div className="vertical_box" style={{ "--items-count": paraGroup.length }}>
  {paraGroup.map((_, index) => (
    <div
    key={index}
    className={`box_scroll ${pageNumber === index ? "active" : ""}`}
    role="button"
    aria-label={`Go to slide ${index + 1}`}
    tabIndex="0"
      onClick={(e) => {
        e.stopPropagation();
        handlePageChange(index);
      }}
    ></div>
  ))}
</div>

            <div className="para_grp_text">
            
          <ReactPageScroller
            pageOnChange={handlePageChange} 
            customPageNumber={pageNumber}
            scrollAnimationDuration={100} 
            // renderAllPagesOnFirstRender={false}
            containerHeight={300}
            verticalAlign="top"

          >
            {paraGroup.map((para, paraIndex) => (
              <div key={paraIndex}  >
                <h1 className="para1">
                  <span className="highlighted">{para.para1}</span>
                </h1>
                <p className="para2">{para.para2}</p>
              </div>
            ))}
          </ReactPageScroller>
          </div>

          <div className="number">
       <h1> {pageNumber + 1} {/* Display the updated number */}</h1>
      </div>
          

</div>
          <div className="homeImg">
            <img src={homeImage}  alt="Luxury Home at Jungle View Homes" loading="lazy"></img>
          </div>
        </div>
        <div className="second-btm-img">
          <img src={secondBtmImg} alt="Decorative Frame - Jungle View Homes"></img>
        </div>
      </div>)}
      
      {/* PROPERTY INSIGHT */}
      <div className="insight_container">
        <Forest/>
      </div>
     
       

      {/* ABOUT US */}
      <div className="about_container">
        <div className="top_about">
          <img src={topGroupImg}  alt="Group of Residents at Jungle View Homes" loading="lazy"></img>
        </div>
        <div className="map_img">
          <div className="map_top_img">
            <img src={map} alt="Map of Jungle View Homes" className="img-fluid" loading="lazy"></img>
          </div>
          <div className="map_btm_img">
            <img src={mapBottom} alt="Map-Bottom"></img>
          </div>
        </div>
        <div className="text_about_center container">
          <h1 className="about_text_1">Your Gateway to Nature</h1>
          <p className="about_text_2">
            Located just minutes from the city, Jungle View Homes offers the
            perfect escape without sacrificing accessibility. Enjoy hiking
            trails, wildlife watching, and the serenity of forest living while
            being close to urban amenities.
          </p>
        </div>
      </div>
      {/* OUR SERVICE */}
      <div className="service_container">
        {!ismageLoading && (<FooterPage/>
)}
 </div>
    </div>
    </>
  );
};

export default JungleHome;
