import React from "react";
import "../footer-page/FooterPage.css";
import "bootstrap/dist/css/bootstrap.min.css";
import footerTopBG from "../../images/footer-page/cloud.jpg";
import topCloud from "../../images/footer-page/cloud-top.svg";
import footerMountain from "../../images/footer-page/service-mountain.svg";
import footerPath from "../../images/footer-page/service-bottom.png";
import footBtmLogo from "../../images/icon-nest.png";
import face from "../../images/footer-page/link-fb.png";
import instagram from "../../images/footer-page/link-instagram.png";
import uTube from "../../images/footer-page/link-tube.png";
import mail from "../../images/footer-page/mail.png";
import app from "../../images/footer-page/whatsapp.svg";
import Line1 from "../../images/footer-page/line.png";


const FooterPage = () => {
  const currentYear = new Date().getFullYear();

  

  return (
    <>
      <div className="footer_container">
  <div className="footer_top_bg img">
    <img 
      src={footerTopBG} 
      className="img-fluid" 
      alt="Nature-inspired background with leaves"
      loading="lazy"
      aria-label="A natural background with leaf elements for an organic feel"
    />
  </div>

  <div className="footer_top_cloud">
    <img 
      src={topCloud} 
      className="img-fluid" 
      alt="Soft clouds in a scenic landscape"
      loading="lazy"
      aria-label="Soft clouds adding a peaceful and serene touch to the footer design"
    />
  </div>

  <div className="container footer_top_text text-center">
    <h1 className="footer_first_text">
      Ready to
      <span className="footer_highlight"> Embrace Nature?</span>
    </h1>
    <p className="footer_sec_text">
    Don’t miss your chance to secure a piece of this tranquil paradise.
    </p>
    <p className="footer_third_text">
    Contact us today for more information!
    </p>

    <div className="contact">
      <a href="/contact" aria-label="Contact Jungle View Homes for More Information">
        <button type="button" className="footer_contact">
          Contact us
        </button>
      </a>
    </div>
  </div>

  <div className="footer_mountain">
    <img 
      src={footerMountain} 
      className="img-fluid" 
      alt="Scenic mountain view representing tranquility and nature"
      loading="lazy"
      aria-label="A breathtaking mountain view, emphasizing harmony with nature"
    />
  </div>
</div>

<div className="footer_path">
  <img 
    src={footerPath} 
    className="img-fluid" 
    alt="Nature Path leading to a scenic view"  
    loading="lazy"
    aria-label="Scenic Nature Path - Jungle View Homes"
  />

  <div className="footer_quick_links">
    <div className="container text-center align-items-start footer_link">
      <div className="link_column_1">
        <div className="footer_nested_row">
          <div className="footBtmLogo">
            <a href="/" title="Go to Jungle View Homes Homepage" aria-label="Go to Jungle View Homepage">
              <img 
                src={footBtmLogo} 
                className="img-fluid" 
                alt="Jungle View Homes Official Logo"
                loading="lazy"
              />
            </a>
          </div>
        </div>

        <div className="footer_social_link">
          <div className="footer_social_link_1">
            <a href="https://www.facebook.com/people/Jungle-View-Homes/61571291915038/" 
               target="_blank" 
               rel="noopener noreferrer" 
               aria-label="Visit Jungle View Homes on Facebook" 
               title="Follow Jungle View Homes on Facebook">
              <img 
                src={face} 
                alt="Follow Jungle View Homes on Facebook" 
                loading="lazy"
              />
            </a>
          </div>

          <div className="footer_social_link_2">
            <a href="https://www.instagram.com/jungleviewhomes/" 
               target="_blank" 
               rel="noopener noreferrer" 
               aria-label="Visit Jungle View Homes on Instagram" 
               title="Follow Jungle View Homes on Instagram">
              <img 
                src={instagram} 
                alt="Follow Jungle View Homes on Instagram" 
                loading="lazy"
              />
            </a>
          </div>

          <div className="footer_social_link_5">
            <a href="/" 
               target="_blank" 
               rel="noopener noreferrer" 
               aria-label="Visit Jungle View Homes on YouTube" 
               title="Subscribe to Jungle View Homes on YouTube">
              <img 
                src={uTube} 
                alt="Subscribe to Jungle View Homes on YouTube" 
                loading="lazy"
              />
            </a>
          </div>
        </div>
      </div>

      <div className="link_column_2">
        <div className="footer_nested_row">
          <h2 className="quick_link">Quick Links</h2>
          <ul className="quick_links_list">
            <li className="link_type">
              <a href="/about" title="Learn more about Jungle View Homes">About Us</a>
            </li>
            <li className="link_type">
              <a href="/contact" title="Contact Jungle View Homes">Contact</a>
            </li>
          </ul>
        </div>
      </div>

      <div className="link_column_3">
        <div className="footer_nested_row">
          <div className="email_app">
            <img src={mail} alt="Email Icon" loading="lazy" />
            <span>Email At</span>
          </div>
          <div className="email_details">
            <a href="mailto:info@jungleviewhomes.com" title="Email Jungle View Homes">
              info@jungleviewhomes.com
            </a>
          </div>
        </div>

        <div className="footer_nested_row">
          <div className="email_app">
            <img src={app} alt="WhatsApp Icon" loading="lazy" />
            <span>General Enquiry</span>
          </div>
          <div className="app_details">
            <a href="tel:+918867064507" title="Call Jungle View Homes">
              +91 8867064507
            </a>
          </div>
        </div>
      </div>
    </div>

    <div className="container">
      <img 
        src={Line1} 
        className="img-fluid" 
        alt="Decorative Line Divider"
        loading="lazy"
      />
      <div className="footer_copy">
        <div className="copy">© {currentYear} Jungle View Homes</div>
        <div className="right">All Rights Reserved</div>
      </div>
    </div>
  </div>
</div>

    
    </>
  );
};
export default FooterPage;
