import './App.css';
import About from './components/about/About.js';
import Contact from './components/contact-us/Contact.js';
import Gallery from './components/explore-gallery/Gallery.js';
import JungleHome from './components/Jungle-home/JungleHome.js';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Services from './components/service/Services.js';
import NotFound from './components/not-found/NotFound.js';

function App() {
  return (
    <div className="App">
          <Router>
      <Routes>
        <Route path="/" element={<JungleHome/>} />
        <Route path="/property" element={<Gallery/>} />
        <Route path="/contact" element={<Contact/>} />
        <Route path="/about" element={<About/>} />
        <Route path="/life" element={<JungleHome/>} />
        <Route path="/services" element={<Services/>} />
        <Route path="*" element={<NotFound/>} />
</Routes>
    </Router>
    </div>
  );
}

export default App;
