import React, { useState, useEffect } from 'react';

const VideoPlayer = ({ videoId }) => {
  return (
    <div id="video-player" style={{ width: window.screen.availWidth * 0.7, height: window.screen.availHeight * 0.7 }}>
      <iframe
        src={videoId}
        frameBorder="0"
        allow="autoplay; fullscreen"
        allowFullScreen
        style={{ width: '100%', height: '100%' }}
      />
    </div>
  );
};

export default VideoPlayer;