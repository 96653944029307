import React from "react";
import { Carousel } from "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useState, useEffect, useCallback} from "react";
import { useSwipeable } from "react-swipeable";
import insightFirstTop from "../../images/insight-top-1.svg";
import insightSecondTop from "../../images/insight-top-2.svg";
import mount from "../../images/insight-mountain.svg";
import mountTree from "../../images/tree.png";
import insightHome from "../../images/insight-home.webp";
import leftDeerPic from "../../images/left-deer.png";
import comPath from "../../images/compound-path.svg";
import leftLeafPic from "../../images/left-leaf.png";
import groupPath from "../../images/group.svg";
import rightLeafPic from "../../images/right-leaf.svg";
import rightDeerPic from "../../images/right-deer.svg";
import "../../components/forest/Forest.css";

const Forest = () => {
    const [activeDot, setActiveDot] = useState(0); 
   const handleDotClick = (index) => {
      setActiveDot(index); 
    const carousel = document.querySelector("#insightCarousel"); 
    const bsCarousel = Carousel.getInstance(carousel); 
    if (bsCarousel) {
      bsCarousel.to(index); 
   } 
     };
    const insights = [
      {
        title: "A Home in Harmony Nature",
        description:
        " Every villa at Jungle View Homes is uniquely crafted to embrace the site’s natural contours and features, ensuring minimal impact on the environment. Instead of altering the landscape, each home is positioned to celebrate it, creating a sense of living within nature rather than simply near it. Trees, slopes, and views remain untouched, framing every villa in a unique.",
          image: insightHome,
      },
      {
        title: "Sustainable Luxury",
        description:
        " Every villa at Jungle View Homes is uniquely crafted to embrace the site’s natural contours and features, ensuring minimal impact on the environment. Instead of altering the landscape, each home is positioned to celebrate it, creating a sense of living within nature rather than simply near it. Trees, slopes, and views remain untouched, framing every villa in a unique.",
        image: insightHome,
      },
      {
        title: "A Wellness-Oriented",
        description:
        " Every villa at Jungle View Homes is uniquely crafted to embrace the site’s natural contours and features, ensuring minimal impact on the environment. Instead of altering the landscape, each home is positioned to celebrate it, creating a sense of living within nature rather than simply near it. Trees, slopes, and views remain untouched, framing every villa in a unique.",
        image: insightHome,
      },
      {
        title: "Where Luxury Tranquility",
        description:
        " Every villa at Jungle View Homes is uniquely crafted to embrace the site’s natural contours and features, ensuring minimal impact on the environment. Instead of altering the landscape, each home is positioned to celebrate it, creating a sense of living within nature rather than simply near it. Trees, slopes, and views remain untouched, framing every villa in a unique.",
        image: insightHome,
      },
    ];
     const swipeHandlers = useSwipeable({
        onSwipedLeft: () => handleNext(),
        onSwipedRight: () => handlePrev(),
        trackMouse: true,
    
      });
    
      const handleNext = () => {
        const nextIndex = (activeDot + 1) % insights.length;
        handleDotClick(nextIndex);
      };
    
      const handlePrev = () => {
        const prevIndex = (activeDot - 1 + insights.length) % insights.length;
        handleDotClick(prevIndex);
      };
      useEffect(() => {
        const carousel = document.querySelector("#insightCarousel");
        const bsCarousel = new Carousel(carousel); 
    
        carousel.addEventListener("slide.bs.carousel", (event) => {
          setActiveDot(event.to); 
        });
    
        return () => {
          bsCarousel.dispose();
        };
      }, []);

      useEffect(() => {
        const carousel = document.querySelector("#insightCarousel");
        if (carousel) {
          new Carousel(carousel, {
            touch: false, // Disable native Bootstrap swipe
          });
        }
      }, []);
  return (
    <>
      <div className="forest_container">
        <div className="top_pic">
          <img src={insightFirstTop} className="img-fluid img-first" alt="Picture" />
          <img src={insightSecondTop} className="img-fluid img-second" alt="Picture" />
        </div>
        <div className="mountain_tree">
            <img src={mount} className="img-fluid img-third" alt="Picture-3"></img>
            <img src={mountTree} className="img-fluid img-fourth" alt="Picture-4"></img>
            <div id="insightCarousel" className="carousel slide text_slide" data-bs-ride="carousel" {...swipeHandlers}>
            <div className="carousel-inner">
              { 
                insights.map((insight, index) => (
                        <div
                          key={index}
                          className={`carousel-item ${index === 0 ? "active" : ""}`}
                        >
                <div className="txt_content container">
                    <h1><span className="highlighted">{insight.title}</span></h1>
                    <p>{insight.description}
                    </p>
                </div>
                <div className="txt_img_container">
                  <div className="slide_pic">
                <img src={insight.image} className="img-fluid" alt={`Insight-Home-${index}`}></img>
                </div>
                 </div>
</div>
              ))}

          </div>
                </div>

        </div>
        <div className="path">
            <div className="left_deer">
                <img src={leftDeerPic} className="img-fluid" alt="Picture-6"></img>
            </div>
            <div className="com_path">
                <img src={comPath} className="img-fluid" alt="Picture-7"></img>
                <div className="dot_path">
<div className="next_content">
        {Array(4)
          .fill(null)
          .map((_, index) => (
            <div
              key={index}
              className={`dot ${activeDot === index ? "active" : ""}`}
              onClick={() => handleDotClick(index)}
            ></div>
          ))}
      </div>                </div>
            </div>
            <div className="leftLeaf">
              <img src={leftLeafPic} className="img-fluid" alt="Picture-6"></img>
            </div>
<div className="group_path">
  <img src={groupPath} className="img-fluid" alt="Picture-7"></img>
</div>
<div className="right_leaf">
  <img src={rightLeafPic} className="img-fluid" alt="Picture-7"></img>
</div>
<div className="right_deer">
  <img src={rightDeerPic} className="img-fluid" alt="Picture-8"></img>
</div>
        </div>

      </div>
    </>
  );
};

export default Forest;
